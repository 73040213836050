import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material'
import React from 'react'
import Image from 'next/image'
import { makeStyles } from 'tss-react/mui'
import PropTypes from 'prop-types'
import theme, { SECONDARY_COLOR } from 'src/styles/theme'
import ChevronDown from 'src/components/icons/ChevronDown'
import { rem } from 'src/common/utils/css'
import sanitize from 'src/common/utils/js/sanitize'
import usePrint from 'src/common/utils/hooks/usePrint'

const useStyles = makeStyles()(() => ({
  wrapper: {
    position: 'relative',
    overflow: 'clip',
    background: `${SECONDARY_COLOR.LIGHT[40]}`,
  },
  backgroundImageLayer: {
    position: 'absolute',
    bottom: -20,
    right: -160,
    height: '141vw',
    width: '200vw',
    zIndex: 1,
    opacity: '0.2',
    [theme.breakpoints.up('md')]: {
      height: '91.269vw',
      width: '128.899vw',
      bottom: -100,
      right: -150,
    },
    [theme.breakpoints.up('lg')]: {
      height: '90.632vw',
      width: '128vw',
      bottom: -180,
      right: -200,
    },
    [theme.breakpoints.up('xl')]: {
      height: '70.806vw',
      width: '100vw',
      bottom: -180,
      right: 0,
    },
  },
  faqContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: rem(24),
    zIndex: 10,
    padding: `${rem(40)} ${rem(24)}`,
    [theme.breakpoints.up('md')]: {
      padding: rem(40),
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      padding: `${rem(64)} ${rem(40)}`,
    },
    [theme.breakpoints.up('xl')]: {
      padding: `${rem(64)} ${rem(157)}`,
    },
  },
  moduleHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    color: theme.palette.primary.dark,
    padding: rem(0),
    width: '100%',
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      width: '33%',
    },
    [theme.breakpoints.up('xl')]: {
      padding: `0 0 0 ${rem(96)}`,
    },
  },
  title: {
    [theme.breakpoints.up('lg')]: {
      width: rem(240),
    },
  },
  moduleContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(18),
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '67%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '60%',
    },
  },
  accordionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(16),
  },
  accordionCategory: {
    color: theme.palette.primary.dark,
  },
  accordion: {
    border: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: '2px !important',
    boxShadow: 'none',
    '&: hover': {
      border: `1px solid ${SECONDARY_COLOR.DARK[80]}`,
    },
  },
  accordionExpanded: {
    border: `1px solid ${SECONDARY_COLOR.DARK[80]}`,
    borderRadius: '2px !important',
    boxShadow: 'none',
  },
  accordionSummary: {
    ...theme.typography.label,
    color: theme.palette.primary.dark,
    padding: `0 ${rem(12)}`,
    '&: hover': {
      color: SECONDARY_COLOR.DARK[80],
    },
    '.Mui-expanded': {
      color: SECONDARY_COLOR.DARK[80],
    },
    // To maintain gap between summary and expand icon
    '.MuiAccordionSummary-expandIconWrapper': {
      marginLeft: rem(10),
    },
    '.MuiAccordionSummary-content>span': {
      paddingTop: rem(3),
      width: '100%',
    },
  },
  accordionDetails: {
    ...theme.typography.body.default,
    padding: `0 0 ${rem(12)} ${rem(12)}`,
    color: theme.palette.presidio.color.DARK_GRAY,
    marginRight: rem(25),
    '& p:first-child': {
      marginBlockStart: 0,
    },
    '& p:last-child': {
      marginBlockEnd: 0,
    },
    '& a': {
      ...theme.typography.body.inlineLink,
      color: theme.palette.primary.dark,
    },
  },
}))

const Faq = (props) => {
  const { classes } = useStyles(props)

  const { data } = props

  // guards
  if (!data || Object.keys(data).length === 0) {
    return null
  }

  const { faq, section_id } = data

  const { title } = faq

  const [isHovering, setIsHovering] = React.useState(null)
  const [expanded, setExpanded] = React.useState({})
  const [expandAll, setExpandAll] = React.useState(false)
  const { aboutToPrint } = usePrint()

  React.useEffect(() => {
    setExpandAll(aboutToPrint)
  }, [aboutToPrint])

  const handleHoverAccordionSummary = (hover, index, nestedIndex) => {
    setIsHovering(hover ? `${index}_${nestedIndex}` : null)
  }

  const handleAccordionExpand = (index, nestedIndex) => (event, expand) => {
    setExpanded((e) => ({
      ...e,
      [index]: { ...e[index], [nestedIndex]: expand },
    }))
  }

  return (
    <div
      className={`${classes.wrapper} module`}
      id={section_id}
      data-id="section"
    >
      <div className={classes.backgroundImageLayer}>
        <Image
          src="/assets/Faq-Background.png"
          alt="background pattern"
          title="Background pattern image"
          layout="fill"
        />
      </div>

      <div className={classes.faqContainer}>
        <div className={classes.moduleHeader}>
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>
        </div>
        <div className={classes.moduleContent}>
          {faq &&
            faq.container &&
            faq.container.length !== 0 &&
            faq.container.map(({ heading, qna }, index) => (
              <div className={classes.accordionContainer} key={index}>
                {heading && (
                  <Typography
                    variant="h3"
                    className={classes.accordionCategory}
                  >
                    {heading}
                  </Typography>
                )}

                {qna &&
                  qna.length !== 0 &&
                  qna.map(({ question, answer }, idx) => (
                    <Accordion
                      expanded={
                        expandAll ||
                        (expanded[index]?.[idx] !== undefined &&
                          expanded[index]?.[idx] !== false)
                      }
                      className={
                        expandAll || expanded[index]?.[idx]
                          ? classes.accordionExpanded
                          : classes.accordion
                      }
                      disableGutters
                      key={`${index}${idx}`}
                      onChange={handleAccordionExpand(index, idx)}
                    >
                      <AccordionSummary
                        onMouseEnter={(e) =>
                          handleHoverAccordionSummary(true, index, idx)
                        }
                        onMouseLeave={(e) => handleHoverAccordionSummary(null)}
                        expandIcon={
                          <ChevronDown
                            height="6"
                            width="12"
                            color={
                              expandAll ||
                              expanded[index]?.[idx] ||
                              isHovering === `${index}_${idx}`
                                ? SECONDARY_COLOR.DARK[80]
                                : theme.palette.primary.dark
                            }
                            gaTag={
                              expandAll || expanded[index]?.[idx]
                                ? 'faq_open'
                                : 'faq_close'
                            }
                          />
                        }
                        aria-controls={`control-${index}-${idx}`}
                        id={`panel-${index}-${idx}`}
                        className={classes.accordionSummary}
                        data-ga-location={
                          expandAll || expanded[index]?.[idx]
                            ? 'faq_open'
                            : 'faq_close'
                        }
                      >
                        <span
                          data-ga-location={
                            expandAll || expanded[index]?.[idx]
                              ? 'faq_open'
                              : 'faq_close'
                          }
                        >
                          {question}
                        </span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          component="div"
                          className={classes.accordionDetails}
                        >
                          {sanitize(answer)}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default Faq

Faq.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    faq: PropTypes.shape({
      container: PropTypes.array,
    }),
    section_id: PropTypes.string,
  }),
}
