import React from 'react'

export default function usePrint() {
  const [aboutToPrint, setAboutToPrint] = React.useState(false)

  const handleAboutToPrint = (print) => {
    setAboutToPrint(print)
  }

  React.useEffect(() => {
    const handleBeforePrint = () => {
      handleAboutToPrint(true)
    }

    const handleAfterPrint = () => {
      handleAboutToPrint(false)
    }

    window.addEventListener('beforeprint', handleBeforePrint)
    window.addEventListener('afterprint', handleAfterPrint)

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint)
      window.removeEventListener('afterprint', handleAfterPrint)
    }
  }, [])

  return { aboutToPrint }
}
